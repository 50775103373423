var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.clazz, style: _vm.boxStyle },
    [
      _c(
        "a-checkbox",
        _vm._b(
          {
            ref: "checkbox",
            attrs: { checked: _vm.innerValue },
            on: { change: _vm.handleChange },
          },
          "a-checkbox",
          _vm.cellProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
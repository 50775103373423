import { render, staticRenderFns } from "./HeaderNotice.vue?vue&type=template&id=06757dae&scoped=true&"
import script from "./HeaderNotice.vue?vue&type=script&lang=js&"
export * from "./HeaderNotice.vue?vue&type=script&lang=js&"
import style0 from "./HeaderNotice.vue?vue&type=style&index=0&id=06757dae&lang=css&"
import style1 from "./HeaderNotice.vue?vue&type=style&index=1&id=06757dae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06757dae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/codes/backstage/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06757dae')) {
      api.createRecord('06757dae', component.options)
    } else {
      api.reload('06757dae', component.options)
    }
    module.hot.accept("./HeaderNotice.vue?vue&type=template&id=06757dae&scoped=true&", function () {
      api.rerender('06757dae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tools/HeaderNotice.vue"
export default component.exports
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-header" }, [
    _c(
      "div",
      { staticClass: "page-header-index-wide" },
      [
        _c(
          "a-breadcrumb",
          { staticClass: "breadcrumb" },
          _vm._l(_vm.breadList, function (item, index) {
            return _c(
              "a-breadcrumb-item",
              { key: index },
              [
                item.name != _vm.name
                  ? _c("router-link", { attrs: { to: { path: item.path } } }, [
                      _vm._v(
                        "\n          " + _vm._s(item.meta.title) + "\n        "
                      ),
                    ])
                  : _c("span", [_vm._v(_vm._s(item.meta.title))]),
              ],
              1
            )
          }),
          1
        ),
        _c("div", { staticClass: "detail" }, [
          !_vm.$route.meta.hiddenHeaderContent
            ? _c("div", { staticClass: "main" }, [
                _c("div", { staticClass: "row" }, [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "logo",
                        attrs: { src: _vm.logo },
                      })
                    : _vm._e(),
                  _vm.title
                    ? _c("h1", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "action" }, [_vm._t("action")], 2),
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm.avatar
                    ? _c(
                        "div",
                        { staticClass: "avatar" },
                        [_c("a-avatar", { attrs: { src: _vm.avatar } })],
                        1
                      )
                    : _vm._e(),
                  this.$slots.content
                    ? _c(
                        "div",
                        { staticClass: "headerContent" },
                        [_vm._t("content")],
                        2
                      )
                    : _vm._e(),
                  this.$slots.extra
                    ? _c("div", { staticClass: "extra" }, [_vm._t("extra")], 2)
                    : _vm._e(),
                ]),
                _c("div", [_vm._t("pageMenu")], 2),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
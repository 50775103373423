var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.currTitle,
        width: 450,
        visible: _vm.visible,
        closable: false,
        maskClosable: _vm.closable,
      },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.closable
            ? _c("a-button", { on: { click: _vm.close } }, [_vm._v("关闭")])
            : _vm._e(),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.departOk } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _c(
        "a-form",
        [
          _c(
            "a-form-item",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: {
                labelCol: { span: 4 },
                wrapperCol: { span: 20 },
                "validate-status": _vm.validate_status,
              },
            },
            [
              _c(
                "a-tooltip",
                { attrs: { placement: "topLeft" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", [_vm._v("您隶属于多部门，请选择当前所在部门")]),
                  ]),
                  _c("a-avatar", {
                    staticStyle: { backgroundColor: "#87d068" },
                    attrs: { icon: "gold" },
                  }),
                ],
                2
              ),
              _c(
                "a-select",
                {
                  class: { "valid-error": _vm.validate_status == "error" },
                  staticStyle: { "margin-left": "10px", width: "80%" },
                  attrs: { placeholder: "请选择登录部门" },
                  model: {
                    value: _vm.departSelected,
                    callback: function ($$v) {
                      _vm.departSelected = $$v
                    },
                    expression: "departSelected",
                  },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "suffixIcon", type: "gold" },
                    slot: "suffixIcon",
                  }),
                  _vm._l(_vm.departList, function (d) {
                    return _c(
                      "a-select-option",
                      { key: d.id, attrs: { value: d.orgCode } },
                      [
                        _vm._v(
                          "\n          " + _vm._s(d.departName) + "\n        "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
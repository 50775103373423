var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.disabled ? "jeecg-form-container-disabled" : "" },
    [
      _c(
        "fieldset",
        { attrs: { disabled: _vm.disabled } },
        [_vm._t("detail")],
        2
      ),
      _vm._t("edit"),
      _c("fieldset", { attrs: { disabled: "" } }, [_vm._t("default")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-input",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.departIds,
              expression: "!departIds",
            },
          ],
          staticClass: "jvxe-select-input",
          attrs: {
            placeholder: "请点击选择部门",
            readOnly: "",
            disabled: _vm.componentDisabled,
          },
          on: { click: _vm.openSelect },
          model: {
            value: _vm.departNames,
            callback: function ($$v) {
              _vm.departNames = $$v
            },
            expression: "departNames",
          },
        },
        [
          _c("a-icon", {
            attrs: { slot: "prefix", type: "cluster", title: "部门选择控件" },
            slot: "prefix",
          }),
        ],
        1
      ),
      _c("j-select-depart-modal", {
        ref: "innerDepartSelectModal",
        attrs: {
          "modal-width": _vm.modalWidth,
          multi: _vm.multi,
          rootOpened: _vm.rootOpened,
          "depart-id": _vm.departIds,
          store: _vm.storeField(),
          text: _vm.textField(),
        },
        on: { ok: _vm.handleOK, initComp: _vm.initComp },
      }),
      _vm.departIds
        ? _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                height: "100%",
                "padding-left": "14px",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "middle",
                  },
                  on: { click: _vm.openSelect },
                },
                [_vm._v(_vm._s(_vm.departNames))]
              ),
              _c("a-icon", {
                staticStyle: {
                  "margin-left": "5px",
                  "vertical-align": "middle",
                },
                attrs: { type: "close-circle", title: "清空" },
                on: { click: _vm.handleEmpty },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
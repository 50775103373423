var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "logo" },
    [
      _c("router-link", { attrs: { to: _vm.routerLinkTo } }, [
        _vm.navTheme === "dark"
          ? _c("img", {
              attrs: { src: require("@/assets/logo-white.png"), alt: "logo" },
            })
          : _c("img", {
              attrs: { src: require("@/assets/logo.png"), alt: "logo" },
            }),
        _vm.showTitle ? _c("h1", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
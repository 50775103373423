var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-popover",
    {
      attrs: {
        trigger: "click",
        placement: "bottomRight",
        autoAdjustOverflow: true,
        arrowPointAtCenter: true,
        overlayClassName: "header-notice-wrapper",
        overlayStyle: { width: "300px", top: "50px" },
      },
      on: { visibleChange: _vm.handleHoverChange },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loadding } },
            [
              _c(
                "a-tabs",
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: _vm.msg1Title } },
                    [
                      _c(
                        "a-list",
                        [
                          _vm._l(_vm.announcement1, function (record, index) {
                            return _c("a-list-item", { key: index }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "5%",
                                    width: "80%",
                                  },
                                },
                                [
                                  _c("p", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.showAnnouncement(record)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(record.titile))]
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        color: "rgba(0,0,0,.45)",
                                        "margin-bottom": "0px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(record.createTime) + " 发布"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  record.priority === "L"
                                    ? _c(
                                        "a-tag",
                                        {
                                          attrs: { color: "blue" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showAnnouncement(
                                                record
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("一般消息")]
                                      )
                                    : _vm._e(),
                                  record.priority === "M"
                                    ? _c(
                                        "a-tag",
                                        {
                                          attrs: { color: "orange" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showAnnouncement(
                                                record
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("重要消息")]
                                      )
                                    : _vm._e(),
                                  record.priority === "H"
                                    ? _c(
                                        "a-tag",
                                        {
                                          attrs: { color: "red" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showAnnouncement(
                                                record
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("紧急消息")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "5px",
                                "text-align": "center",
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "dashed", block: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toMyAnnouncement()
                                    },
                                  },
                                },
                                [_vm._v("查看更多")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: _vm.msg2Title } },
                    [
                      _c(
                        "a-list",
                        [
                          _vm._l(_vm.announcement2, function (record, index) {
                            return _c("a-list-item", { key: index }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "5%",
                                    width: "80%",
                                  },
                                },
                                [
                                  _c("p", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.showAnnouncement(record)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(record.titile))]
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        color: "rgba(0,0,0,.45)",
                                        "margin-bottom": "0px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(record.createTime) + " 发布"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  record.priority === "L"
                                    ? _c(
                                        "a-tag",
                                        {
                                          attrs: { color: "blue" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showAnnouncement(
                                                record
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("一般消息")]
                                      )
                                    : _vm._e(),
                                  record.priority === "M"
                                    ? _c(
                                        "a-tag",
                                        {
                                          attrs: { color: "orange" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showAnnouncement(
                                                record
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("重要消息")]
                                      )
                                    : _vm._e(),
                                  record.priority === "H"
                                    ? _c(
                                        "a-tag",
                                        {
                                          attrs: { color: "red" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showAnnouncement(
                                                record
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("紧急消息")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "5px",
                                "text-align": "center",
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "dashed", block: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toMyAnnouncement()
                                    },
                                  },
                                },
                                [_vm._v("查看更多")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "header-notice", on: { click: _vm.fetchNotice } },
        [
          _c(
            "a-badge",
            { attrs: { count: _vm.msgTotal } },
            [
              _c("a-icon", {
                staticStyle: { "font-size": "16px", padding: "4px" },
                attrs: { type: "bell" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-announcement", {
        ref: "ShowAnnouncement",
        on: { ok: _vm.modalFormOk },
      }),
      _c("dynamic-notice", {
        ref: "showDynamNotice",
        attrs: { path: _vm.openPath, formData: _vm.formData },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
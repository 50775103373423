var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "j-modal",
    {
      attrs: {
        title: "详细信息",
        width: 1200,
        visible: _vm.visible,
        "switch-fullscreen": "",
        fullscreen: _vm.fullscreen,
      },
      on: {
        ok: _vm.handleOk,
        cancel: _vm.close,
        "update:fullscreen": function ($event) {
          _vm.fullscreen = $event
        },
      },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.visible
          ? _c(
              "div",
              [
                _vm._t("mainForm", null, { row: _vm.row, column: _vm.column }),
                _vm._t("subForm", null, { row: _vm.row, column: _vm.column }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
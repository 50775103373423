var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "j-area-linkage" },
    [
      _vm.reloading
        ? _c("div", [_c("span", [_vm._v(" Reloading... ")])])
        : _vm._type === _vm.enums.type[0]
        ? _c(
            "area-cascader",
            _vm._g(
              _vm._b(
                {
                  style: { width: _vm.width },
                  attrs: { value: _vm.innerValue, data: _vm.pcaa, level: 1 },
                  on: { change: _vm.handleChange },
                },
                "area-cascader",
                _vm.$attrs,
                false
              ),
              _vm._listeners
            )
          )
        : _vm._type === _vm.enums.type[1]
        ? _c(
            "area-select",
            _vm._g(
              _vm._b(
                {
                  attrs: { value: _vm.innerValue, data: _vm.pcaa, level: 2 },
                  on: { change: _vm.handleChange },
                },
                "area-select",
                _vm.$attrs,
                false
              ),
              _vm._listeners
            )
          )
        : _c("div", [
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(" Bad type value: " + _vm._s(_vm._type)),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
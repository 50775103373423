var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: !_vm.$route.meta.pageHeader ? "margin: -10px -24px 0;" : null },
    [
      !_vm.$route.meta.pageHeader
        ? _c(
            "page-header",
            { attrs: { title: _vm.title, logo: _vm.logo, avatar: _vm.avatar } },
            [
              _vm._t("action", null, { slot: "action" }),
              _vm._t("headerContent", null, { slot: "content" }),
              !this.$slots.headerContent && _vm.desc
                ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "rgba(0,0,0,.65)",
                        },
                      },
                      [_vm._v(_vm._s(_vm.desc))]
                    ),
                    _c(
                      "div",
                      { staticClass: "link" },
                      [
                        _vm._l(_vm.linkList, function (link, index) {
                          return [
                            _c(
                              "a",
                              { key: index, attrs: { href: link.href } },
                              [
                                _c("a-icon", { attrs: { type: link.icon } }),
                                _c("span", [_vm._v(_vm._s(link.title))]),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._t("extra", null, { slot: "extra" }),
              _c("div", { attrs: { slot: "pageMenu" }, slot: "pageMenu" }, [
                _vm.search
                  ? _c(
                      "div",
                      { staticClass: "page-menu-search" },
                      [
                        _c("a-input-search", {
                          staticStyle: { width: "80%", "max-width": "522px" },
                          attrs: {
                            placeholder: "请输入...",
                            size: "large",
                            enterButton: "搜索",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tabs && _vm.tabs.items
                  ? _c(
                      "div",
                      { staticClass: "page-menu-tabs" },
                      [
                        _c(
                          "a-tabs",
                          {
                            attrs: {
                              tabBarStyle: { margin: 0 },
                              activeKey: _vm.tabs.active(),
                            },
                            on: { change: _vm.tabs.callback },
                          },
                          _vm._l(_vm.tabs.items, function (item) {
                            return _c("a-tab-pane", {
                              key: item.key,
                              attrs: { tab: item.title },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { class: ["page-header-index-wide"] },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "dragDiv", staticClass: "drag" }, [
    _c("div", { staticClass: "drag_bg" }),
    _c("div", { staticClass: "drag_text" }, [_vm._v(_vm._s(_vm.confirmWords))]),
    _c("div", {
      ref: "moveDiv",
      staticClass: "handler handler_bg",
      class: { handler_ok_bg: _vm.confirmSuccess },
      staticStyle: {
        border: "0.5px solid #fff",
        height: "34px",
        position: "absolute",
        top: "0px",
        left: "0px",
      },
      on: {
        mousedown: function ($event) {
          return _vm.mousedownFn($event)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
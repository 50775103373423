var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-input-search",
        {
          attrs: {
            placeholder: "请先选择用户",
            readOnly: "",
            unselectable: "on",
          },
          on: { search: _vm.onSearchDepUser },
          model: {
            value: _vm.textVals,
            callback: function ($$v) {
              _vm.textVals = $$v
            },
            expression: "textVals",
          },
        },
        [
          _c(
            "a-button",
            {
              attrs: { slot: "enterButton", disabled: _vm.disabled },
              slot: "enterButton",
            },
            [_vm._v("选择用户")]
          ),
        ],
        1
      ),
      _c("j-select-user-by-dep-modal", {
        ref: "selectModal",
        attrs: {
          "modal-width": _vm.modalWidth,
          multi: _vm.multi,
          "user-ids": _vm.value,
          store: _vm.storeField,
          text: _vm.textField,
        },
        on: { ok: _vm.selectOK, initComp: _vm.initComp },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "j-modal",
    {
      attrs: {
        title: _vm.title,
        width: _vm.modelStyle.width,
        visible: _vm.visible,
        bodyStyle: _vm.bodyStyle,
        switchFullscreen: _vm.switchFullscreen,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
            _vm._v("关闭"),
          ]),
          _vm.record.openType === "url"
            ? _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.toHandle } },
                [_vm._v("去处理")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "a-card",
        { staticClass: "daily-article", attrs: { loading: _vm.loading } },
        [
          _c("a-card-meta", {
            attrs: {
              title: _vm.record.titile,
              description:
                "发布人：" +
                _vm.record.sender +
                " 发布时间： " +
                _vm.record.sendTime,
            },
          }),
          _c("a-divider"),
          _c("span", {
            staticClass: "article-content",
            domProps: { innerHTML: _vm._s(_vm.record.msgContent) },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
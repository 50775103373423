var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "j-select-biz-component",
    _vm._g(
      _vm._b(
        { attrs: { width: 1000 } },
        "j-select-biz-component",
        _vm.configs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
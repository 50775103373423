var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" }, attrs: { id: _vm.containerId } },
    [
      _c(
        "div",
        {
          staticClass: "movety-container",
          staticStyle: {
            padding: "0 8px",
            position: "absolute",
            "z-index": "91",
            height: "32px",
            width: "104px",
            "text-align": "center",
          },
          style: {
            top: _vm.top + "px",
            left: _vm.left + "px",
            display: _vm.moveDisplay,
          },
        },
        [
          _c(
            "div",
            {
              class: _vm.showMoverTask ? "uploadty-mover-mask" : "movety-opt",
              staticStyle: { "margin-top": "12px" },
              attrs: { id: _vm.containerId + "-mover" },
            },
            [
              _c(
                "a",
                {
                  staticStyle: { margin: "0 5px" },
                  on: { click: _vm.moveLast },
                },
                [
                  _c("a-icon", {
                    staticStyle: { color: "#fff", "font-size": "16px" },
                    attrs: { type: "arrow-left" },
                  }),
                ],
                1
              ),
              _c(
                "a",
                {
                  staticStyle: { margin: "0 5px" },
                  on: { click: _vm.moveNext },
                },
                [
                  _c("a-icon", {
                    staticStyle: { color: "#fff", "font-size": "16px" },
                    attrs: { type: "arrow-right" },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "a-upload",
        {
          class: { "uploadty-disabled": _vm.disabled },
          attrs: {
            name: "file",
            multiple: _vm.multiple,
            action: _vm.uploadAction,
            headers: _vm.headers,
            data: { biz: _vm.bizPath },
            fileList: _vm.fileList,
            beforeUpload: _vm.doBeforeUpload,
            disabled: _vm.disabled,
            returnUrl: _vm.returnUrl,
            listType: _vm.complistType,
          },
          on: { change: _vm.handleChange, preview: _vm.handlePreview },
        },
        [
          [
            _vm.isImageComp
              ? _c(
                  "div",
                  [
                    _c("a-icon", { attrs: { type: "plus" } }),
                    _c("div", { staticClass: "ant-upload-text" }, [
                      _vm._v(_vm._s(_vm.text)),
                    ]),
                  ],
                  1
                )
              : _vm.buttonVisible
              ? _c(
                  "a-button",
                  [
                    _c("a-icon", { attrs: { type: "upload" } }),
                    _vm._v(_vm._s(_vm.text) + "\n      "),
                  ],
                  1
                )
              : _vm._e(),
          ],
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-dropdown",
    { attrs: { trigger: ["click"] } },
    [
      _c(
        "div",
        { staticClass: "j-vxe-ds-icons" },
        [
          _c("a-icon", { attrs: { type: "align-left" } }),
          _c("a-icon", { attrs: { type: "align-right" } }),
        ],
        1
      ),
      _c(
        "a-menu",
        { attrs: { slot: "overlay" }, slot: "overlay" },
        [
          _c(
            "a-menu-item",
            {
              key: "0",
              attrs: { disabled: _vm.disabledMoveUp },
              on: { click: _vm.handleRowMoveUp },
            },
            [_vm._v("向上移")]
          ),
          _c(
            "a-menu-item",
            {
              key: "1",
              attrs: { disabled: _vm.disabledMoveDown },
              on: { click: _vm.handleRowMoveDown },
            },
            [_vm._v("向下移")]
          ),
          _c("a-menu-divider"),
          _c(
            "a-menu-item",
            { key: "3", on: { click: _vm.handleRowInsertDown } },
            [_vm._v("插入一行")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
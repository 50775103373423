var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "j-markdown-editor", attrs: { id: _vm.id } }),
    _vm.isShow
      ? _c(
          "div",
          [
            _c(
              "j-modal",
              {
                attrs: {
                  title: "图片上传",
                  visible: _vm.dialogVisible,
                  width: "30%",
                  "before-close": _vm.handleClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                  ok: _vm.handleOk,
                },
              },
              [
                _c(
                  "a-tabs",
                  {
                    attrs: { "default-active-key": "1" },
                    on: { change: _vm.handleChange },
                  },
                  [
                    _c(
                      "a-tab-pane",
                      {
                        key: "1",
                        attrs: { tab: "本地图片上传", forceRender: true },
                      },
                      [
                        _c("j-upload", {
                          attrs: { number: 1 },
                          model: {
                            value: _vm.fileList,
                            callback: function ($$v) {
                              _vm.fileList = $$v
                            },
                            expression: "fileList",
                          },
                        }),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "20px" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请填写备注" },
                              model: {
                                value: _vm.remark,
                                callback: function ($$v) {
                                  _vm.remark = $$v
                                },
                                expression: "remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-tab-pane",
                      {
                        key: "2",
                        attrs: { tab: "网络图片地址", forceRender: true },
                      },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请填写网络图片地址" },
                          model: {
                            value: _vm.networkPic,
                            callback: function ($$v) {
                              _vm.networkPic = $$v
                            },
                            expression: "networkPic",
                          },
                        }),
                        _c("a-input", {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { placeholder: "请填写备注" },
                          model: {
                            value: _vm.remark,
                            callback: function ($$v) {
                              _vm.remark = $$v
                            },
                            expression: "remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-breadcrumb",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.breadList, function (item, index) {
      return _c(
        "a-breadcrumb-item",
        { key: index },
        [
          item.name != _vm.name
            ? _c("router-link", { attrs: { to: { path: item.path } } }, [
                _vm._v("\n      " + _vm._s(item.meta.title) + "\n    "),
              ])
            : _c("span", [_vm._v(_vm._s(item.meta.title))]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-tree-select", {
    staticStyle: { width: "100%" },
    attrs: {
      allowClear: "",
      labelInValue: "",
      disabled: _vm.disabled,
      dropdownStyle: { maxHeight: "400px", overflow: "auto" },
      placeholder: _vm.placeholder,
      loadData: _vm.asyncLoadTreeData,
      value: _vm.treeValue,
      treeData: _vm.treeData,
      multiple: _vm.multiple,
    },
    on: { change: _vm.onChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-select",
    _vm._b(
      {
        ref: "select",
        staticStyle: { width: "100%" },
        attrs: {
          value: _vm.innerValue,
          allowClear: "",
          filterOption: _vm.handleSelectFilterOption,
        },
        on: {
          blur: _vm.handleBlur,
          change: _vm.handleChange,
          search: _vm.handleSearchSelect,
        },
      },
      "a-select",
      _vm.selectProps,
      false
    ),
    [
      _vm.loading
        ? _c(
            "div",
            { attrs: { slot: "notFoundContent" }, slot: "notFoundContent" },
            [
              _c("a-icon", { attrs: { type: "loading" } }),
              _c("span", [_vm._v(" 加载中…")]),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.selectOptions, function (option) {
        return [
          _c(
            "a-select-option",
            {
              key: option.value,
              attrs: { value: option.value, disabled: option.disabled },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    option.text || option.label || option.title || option.value
                  )
                ),
              ]),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-wrapper", class: _vm.theme },
    [
      _c(
        "span",
        { staticClass: "action", on: { click: _vm.showClick } },
        [_c("a-icon", { attrs: { type: "search" } })],
        1
      ),
      _c(
        _vm.searchMenuComp,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchMenuVisible || _vm.isMobile(),
              expression: "searchMenuVisible || isMobile()",
            },
          ],
          tag: "component",
          staticClass: "borders",
          attrs: {
            visible: _vm.searchMenuVisible,
            title: "搜索菜单",
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.searchMenuVisible = false
            },
          },
        },
        [
          _c(
            "a-select",
            {
              staticClass: "search-input",
              style: _vm.isMobile()
                ? { width: "100%", marginBottom: "50px" }
                : {},
              attrs: {
                showSearch: "",
                showArrow: false,
                placeholder: "搜索菜单",
                optionFilterProp: "children",
                filterOption: _vm.filterOption,
                open: _vm.isMobile() ? true : null,
                getPopupContainer: (node) => node.parentNode,
              },
              on: { change: _vm.searchMethods, blur: _vm.hiddenClick },
            },
            _vm._l(_vm.searchMenuOptions, function (site, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: site.id } },
                [_vm._v(_vm._s(site.meta.title))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("header-notice", { staticClass: "action" }),
      _c(
        "a-dropdown",
        [
          _c(
            "span",
            {
              staticClass:
                "action action-full ant-dropdown-link user-dropdown-menu",
            },
            [
              _c("a-avatar", {
                staticClass: "avatar",
                attrs: { size: "small", src: _vm.getAvatar() },
              }),
              _vm.isDesktop()
                ? _c("span", [_vm._v("欢迎您，" + _vm._s(_vm.nickname()))])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-menu",
            {
              staticClass: "user-dropdown-menu-wrapper",
              attrs: { slot: "overlay" },
              slot: "overlay",
            },
            [
              _c(
                "a-menu-item",
                { key: "0" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "account-center" } } },
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _c("span", [_vm._v("个人中心")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "1" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "account-settings-base" } } },
                    [
                      _c("a-icon", { attrs: { type: "setting" } }),
                      _c("span", [_vm._v("账户设置")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "3", on: { click: _vm.systemSetting } },
                [
                  _c("a-icon", { attrs: { type: "tool" } }),
                  _c("span", [_vm._v("系统设置")]),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "4", on: { click: _vm.updatePassword } },
                [
                  _c("a-icon", { attrs: { type: "setting" } }),
                  _c("span", [_vm._v("密码修改")]),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "5", on: { click: _vm.updateCurrentDepart } },
                [
                  _c("a-icon", { attrs: { type: "cluster" } }),
                  _c("span", [_vm._v("切换部门")]),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "6", on: { click: _vm.clearCache } },
                [
                  _c("a-icon", { attrs: { type: "sync" } }),
                  _c("span", [_vm._v("清理缓存")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("span", { staticClass: "action" }, [
        _c(
          "a",
          {
            staticClass: "logout_title",
            attrs: { href: "javascript:;" },
            on: { click: _vm.handleLogout },
          },
          [
            _c("a-icon", { attrs: { type: "logout" } }),
            _vm.isDesktop() ? _c("span", [_vm._v(" 退出登录")]) : _vm._e(),
          ],
          1
        ),
      ]),
      _c("user-password", { ref: "userPassword" }),
      _c("depart-select", {
        ref: "departSelect",
        attrs: { closable: true, title: "部门切换" },
      }),
      _c("setting-drawer", {
        ref: "settingDrawer",
        attrs: { closable: true, title: "系统设置" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
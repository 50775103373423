var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-tooltip",
    { attrs: { placement: "topLeft" } },
    [
      _c("template", { slot: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.value))]),
      ]),
      _vm._v("\n  " + _vm._s(_vm._f("ellipsis")(_vm.value, _vm.length)) + "\n"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b({}, "div", _vm.fullScreenParentProps, false),
    [
      _vm.fullScreen
        ? _c("a-icon", {
            staticClass: "full-screen-icon",
            attrs: { type: _vm.iconType },
            on: { click: () => (_vm.fullCoder = !_vm.fullCoder) },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "code-editor-cust full-screen-child" },
        [
          _c("textarea", { ref: "textarea" }),
          _c(
            "span",
            {
              staticClass: "null-tip",
              class: { "null-tip-hidden": _vm.hasCode },
              style: _vm.nullTipStyle,
              on: { click: _vm.nullTipClick },
            },
            [_vm._v(_vm._s(_vm.placeholderShow))]
          ),
          _vm.languageChange
            ? [
                _c(
                  "a-select",
                  {
                    staticClass: "code-mode-select",
                    attrs: { size: "small", placeholder: "请选择主题" },
                    on: { change: _vm.changeMode },
                    model: {
                      value: _vm.mode,
                      callback: function ($$v) {
                        _vm.mode = $$v
                      },
                      expression: "mode",
                    },
                  },
                  _vm._l(_vm.modes, function (mode) {
                    return _c(
                      "a-select-option",
                      { key: mode.value, attrs: { value: mode.value } },
                      [
                        _vm._v(
                          "\n          " + _vm._s(mode.label) + "\n        "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-radio-group",
    _vm._b(
      {
        class: _vm.clazz,
        attrs: { value: _vm.innerValue },
        on: { change: (e) => _vm.handleChangeCommon(e.target.value) },
      },
      "a-radio-group",
      _vm.cellProps,
      false
    ),
    _vm._l(_vm.originColumn.options, function (item) {
      return _c(
        "a-radio",
        {
          key: item.value,
          attrs: { value: item.value },
          on: { click: ($event) => _vm.handleRadioClick(item, $event) },
        },
        [_vm._v(_vm._s(item.text) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
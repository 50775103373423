var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tagType == "radio"
    ? _c(
        "a-radio-group",
        {
          attrs: { value: _vm.getValueSting, disabled: _vm.disabled },
          on: { change: _vm.handleInput },
        },
        _vm._l(_vm.dictOptions, function (item, key) {
          return _c("a-radio", { key: key, attrs: { value: item.value } }, [
            _vm._v(_vm._s(item.text)),
          ])
        }),
        1
      )
    : _vm.tagType == "radioButton"
    ? _c(
        "a-radio-group",
        {
          attrs: {
            buttonStyle: "solid",
            value: _vm.getValueSting,
            disabled: _vm.disabled,
          },
          on: { change: _vm.handleInput },
        },
        _vm._l(_vm.dictOptions, function (item, key) {
          return _c(
            "a-radio-button",
            { key: key, attrs: { value: item.value } },
            [_vm._v(_vm._s(item.text))]
          )
        }),
        1
      )
    : _vm.tagType == "select"
    ? _c(
        "a-select",
        {
          attrs: {
            getPopupContainer: _vm.getPopupContainer,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            value: _vm.getValueSting,
          },
          on: { change: _vm.handleInput },
        },
        [
          _c("a-select-option", { attrs: { value: undefined } }, [
            _vm._v("请选择"),
          ]),
          _vm._l(_vm.dictOptions, function (item, key) {
            return _c(
              "a-select-option",
              { key: key, attrs: { value: item.value } },
              [
                _c(
                  "span",
                  {
                    staticStyle: { display: "inline-block", width: "100%" },
                    attrs: { title: item.text || item.label },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(item.text || item.label) + "\n    "
                    ),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.headerBarFixed
    ? _c(
        "a-layout-header",
        {
          class: [
            _vm.fixedHeader && "ant-header-fixedHeader",
            _vm.sidebarOpened
              ? "ant-header-side-opened"
              : "ant-header-side-closed",
          ],
          style: { padding: "0" },
        },
        [
          _vm.mode === "sidemenu"
            ? _c(
                "div",
                { staticClass: "header", class: _vm.theme },
                [
                  _vm.device === "mobile"
                    ? _c("a-icon", {
                        staticClass: "trigger",
                        attrs: {
                          type: _vm.collapsed ? "menu-fold" : "menu-unfold",
                        },
                        on: { click: _vm.toggle },
                      })
                    : _c("a-icon", {
                        staticClass: "trigger",
                        attrs: {
                          type: _vm.collapsed ? "menu-unfold" : "menu-fold",
                        },
                        on: { click: _vm.toggle },
                      }),
                  _c("user-menu", { attrs: { theme: _vm.theme } }),
                ],
                1
              )
            : _c("div", { class: ["top-nav-header-index", _vm.theme] }, [
                _c(
                  "div",
                  { staticClass: "header-index-wide" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "header-index-left",
                        style: _vm.topMenuStyle.headerIndexLeft,
                      },
                      [
                        _c("logo", {
                          staticClass: "top-nav-header",
                          style: _vm.topMenuStyle.topNavHeader,
                          attrs: { "show-title": _vm.device !== "mobile" },
                        }),
                        _vm.device !== "mobile"
                          ? _c(
                              "div",
                              { style: _vm.topMenuStyle.topSmenuStyle },
                              [
                                _c("s-menu", {
                                  attrs: {
                                    mode: "horizontal",
                                    menu: _vm.menus,
                                    theme: _vm.theme,
                                  },
                                  on: {
                                    updateMenuTitle: _vm.handleUpdateMenuTitle,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("a-icon", {
                              staticClass: "trigger",
                              attrs: {
                                type: _vm.collapsed
                                  ? "menu-fold"
                                  : "menu-unfold",
                              },
                              on: { click: _vm.toggle },
                            }),
                      ],
                      1
                    ),
                    _c("user-menu", {
                      staticClass: "header-index-right",
                      style: _vm.topMenuStyle.headerIndexRight,
                      attrs: { theme: _vm.theme },
                    }),
                  ],
                  1
                ),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
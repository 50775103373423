var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "j-input-pop",
    _vm._b(
      {
        staticStyle: { width: "100%" },
        attrs: { value: _vm.innerValue, width: 300, height: 210 },
        on: { change: _vm.handleChangeCommon },
      },
      "j-input-pop",
      _vm.cellProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
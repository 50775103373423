var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-checkbox-group",
    _vm._b(
      {
        attrs: { options: _vm.options, value: _vm.checkboxArray },
        on: { change: _vm.onChange },
      },
      "a-checkbox-group",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
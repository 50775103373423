var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("reload-effect", {
    attrs: { vNode: _vm.innerValue, effect: _vm.reloadEffect },
    on: { "effect-end": _vm.handleEffectEnd },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
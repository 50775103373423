var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-input",
    _vm._b(
      {
        ref: "input",
        attrs: { value: _vm.innerValue },
        on: { blur: _vm.handleBlur, change: _vm.handleChange },
      },
      "a-input",
      _vm.cellProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-time-picker", {
    attrs: {
      disabled: _vm.disabled || _vm.readOnly,
      placeholder: _vm.placeholder,
      value: _vm.momVal,
      format: _vm.dateFormat,
      getCalendarContainer: _vm.getCalendarContainer,
    },
    on: { change: _vm.handleTimeChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
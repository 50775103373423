var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: "导入EXCEL",
        width: 600,
        visible: _vm.visible,
        confirmLoading: _vm.uploading,
      },
      on: { cancel: _vm.handleClose },
    },
    [
      _vm.online
        ? _c("div", { staticStyle: { margin: "0px 0px 5px 1px" } }, [
            _c(
              "span",
              {
                staticStyle: {
                  display: "inline-block",
                  height: "32px",
                  "line-height": "32px",
                  "vertical-align": "middle",
                },
              },
              [_vm._v("是否开启校验:")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  display: "inline-block",
                  height: "32px",
                  "margin-left": "6px",
                },
              },
              [
                _c("a-switch", {
                  attrs: {
                    checked: _vm.validateStatus == 1,
                    "checked-children": "是",
                    "un-checked-children": "否",
                    size: "small",
                  },
                  on: { change: _vm.handleChangeValidateStatus },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "a-upload",
        {
          attrs: {
            name: "file",
            multiple: true,
            accept: ".xls,.xlsx",
            fileList: _vm.fileList,
            remove: _vm.handleRemove,
            beforeUpload: _vm.beforeUpload,
          },
        },
        [
          _c(
            "a-button",
            [
              _c("a-icon", { attrs: { type: "upload" } }),
              _vm._v("\n      选择导入文件\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.fileList.length === 0,
                loading: _vm.uploading,
              },
              on: { click: _vm.handleImport },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.uploading ? "上传中..." : "开始上传") +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tagType == "checkbox"
    ? _c(
        "a-checkbox-group",
        {
          attrs: { value: _vm.arrayValue, disabled: _vm.disabled },
          on: { change: _vm.onChange },
        },
        _vm._l(_vm.dictOptions, function (item, key) {
          return _c("a-checkbox", { key: key, attrs: { value: item.value } }, [
            _vm._v(_vm._s(item.text || item.label)),
          ])
        }),
        1
      )
    : _vm.tagType == "select"
    ? _c(
        "a-select",
        {
          attrs: {
            value: _vm.arrayValue,
            disabled: _vm.disabled,
            mode: "multiple",
            placeholder: _vm.placeholder,
            getPopupContainer: _vm.getParentContainer,
            optionFilterProp: "children",
            filterOption: _vm.filterOption,
            allowClear: "",
          },
          on: { change: _vm.onChange },
        },
        _vm._l(_vm.dictOptions, function (item, index) {
          return _c(
            "a-select-option",
            { key: index, attrs: { value: item.value } },
            [
              _c(
                "span",
                {
                  staticStyle: { display: "inline-block", width: "100%" },
                  attrs: { title: item.text || item.label },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(item.text || item.label) + "\n    "
                  ),
                ]
              ),
            ]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
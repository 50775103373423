var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-popover",
    {
      attrs: {
        visible: _vm.visible,
        placement: _vm.placement,
        overlayClassName: "j-vxe-popover-overlay",
        overlayStyle: _vm.overlayStyle,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "j-vxe-popover-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("div", [_vm._v("子表")]),
          _c(
            "div",
            {
              staticClass: "j-vxe-popover-title-close",
              on: { click: _vm.close },
            },
            [_c("a-icon", { attrs: { type: "close" } })],
            1
          ),
        ]
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.visible
                ? _vm._t("subForm", null, { row: _vm.row, column: _vm.column })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { ref: "div", staticClass: "j-vxe-popover-div" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
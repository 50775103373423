var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.comp
    ? _c(_vm.comp, {
        ref: "compModel",
        tag: "component",
        attrs: { formData: _vm.formData },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.fileType === "image" ? "图片上传" : "文件上传",
            width: _vm.width,
            visible: _vm.visible,
            cancelText: "取消",
          },
          on: { ok: _vm.ok, cancel: _vm.close },
        },
        [
          _c("j-upload", {
            attrs: {
              "file-type": _vm.fileType,
              value: _vm.filePath,
              disabled: _vm.disabled,
              number: _vm.number,
            },
            on: { change: _vm.handleChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["user-layout-wrapper", _vm.device], attrs: { id: "userLayout" } },
    [_c("div", { staticClass: "container" }, [_vm._m(0), _c("route-view")], 1)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "header" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("@/assets/logo.png"), alt: "logo" },
          }),
          _c("span", { staticClass: "title" }, [_vm._v("钟泰换电管理平台")]),
        ]),
      ]),
      _c("div", { staticClass: "desc" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
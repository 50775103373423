var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "global-layout",
    { on: { dynamicRouterShow: _vm.dynamicRouterShow } },
    [
      _c("contextmenu", {
        staticStyle: { "z-index": "9999" },
        attrs: { itemList: _vm.menuItemList, visible: _vm.menuVisible },
        on: {
          "update:visible": function ($event) {
            _vm.menuVisible = $event
          },
          select: _vm.onMenuSelect,
        },
      }),
      _vm.multipage
        ? _c(
            "a-tabs",
            {
              staticClass: "tab-layout-tabs",
              staticStyle: { height: "52px" },
              attrs: {
                "active-key": _vm.activePage,
                "hide-add": true,
                type: "editable-card",
              },
              on: {
                change: _vm.changePage,
                tabClick: _vm.tabCallBack,
                edit: _vm.editPage,
              },
              nativeOn: {
                contextmenu: function ($event) {
                  return ((e) => _vm.onContextmenu(e)).apply(null, arguments)
                },
              },
            },
            _vm._l(_vm.pageList, function (page) {
              return _c(
                "a-tab-pane",
                {
                  key: page.fullPath,
                  attrs: {
                    id: page.fullPath,
                    closable: !(page.meta.title == "首页"),
                  },
                },
                [
                  _c(
                    "span",
                    {
                      attrs: { slot: "tab", pagekey: page.fullPath },
                      slot: "tab",
                    },
                    [_vm._v(_vm._s(page.meta.title))]
                  ),
                ]
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { margin: "12px 12px 0" } },
        [
          _vm.multipage
            ? _c(
                "keep-alive",
                [_vm.reloadFlag ? _c("router-view") : _vm._e()],
                1
              )
            : [_vm.reloadFlag ? _c("router-view") : _vm._e()],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
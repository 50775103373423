var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l([_vm.innerFile || {}], function (file, fileKey) {
        return _vm.hasFile
          ? [
              _c(
                "a-input",
                { key: fileKey, attrs: { readOnly: true, value: file.name } },
                [
                  _c(
                    "template",
                    { staticStyle: { width: "30px" }, slot: "addonBefore" },
                    [
                      file.status === "uploading"
                        ? _c(
                            "a-tooltip",
                            {
                              attrs: {
                                title: `上传中(${Math.floor(file.percent)}%)`,
                              },
                            },
                            [_c("a-icon", { attrs: { type: "loading" } })],
                            1
                          )
                        : file.status === "done"
                        ? _c(
                            "a-tooltip",
                            { attrs: { title: "上传完成" } },
                            [
                              _c("a-icon", {
                                staticStyle: { color: "#00DB00" },
                                attrs: { type: "check-circle" },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "a-tooltip",
                            { attrs: { title: file.message || "上传失败" } },
                            [
                              _c("a-icon", {
                                staticStyle: { color: "red" },
                                attrs: { type: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  file.status === "uploading"
                    ? _c(
                        "span",
                        { attrs: { slot: "addonAfter" }, slot: "addonAfter" },
                        [_vm._v(_vm._s(Math.floor(file.percent)) + "%")]
                      )
                    : _vm.originColumn.allowDownload !== false ||
                      _vm.originColumn.allowRemove !== false
                    ? _c(
                        "template",
                        { slot: "addonAfter" },
                        [
                          _c(
                            "a-dropdown",
                            {
                              attrs: {
                                trigger: ["click"],
                                placement: "bottomRight",
                              },
                            },
                            [
                              _c(
                                "a-tooltip",
                                { attrs: { title: "操作" } },
                                [
                                  _c("a-icon", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { type: "setting" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  _vm.originColumn.allowDownload !== false
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: _vm.handleClickDownloadFile,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "download" },
                                              }),
                                              _vm._v(" 下载"),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.originColumn.allowRemove !== false
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: _vm.handleClickDeleteFile,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "delete" },
                                              }),
                                              _vm._v(" 删除"),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          : _vm._e()
      }),
      _c(
        "a-upload",
        _vm._b(
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hasFile,
                expression: "!hasFile",
              },
            ],
            attrs: {
              name: "file",
              data: { isup: 1 },
              multiple: false,
              action: _vm.originColumn.action,
              headers: _vm.uploadHeaders,
              showUploadList: false,
            },
            on: { change: _vm.handleChangeUpload },
          },
          "a-upload",
          _vm.cellProps,
          false
        ),
        [
          _c("a-button", { attrs: { icon: "upload" } }, [
            _vm._v(_vm._s(_vm.originColumn.btnText || "点击上传")),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
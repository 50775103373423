var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "j-popup",
    _vm._b(
      { on: { input: _vm.handlePopupInput } },
      "j-popup",
      _vm.popupProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
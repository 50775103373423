var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-layout",
    { staticClass: "layout", class: [_vm.device] },
    [
      _vm.layoutMode === "sidemenu"
        ? [
            _vm.device === "mobile"
              ? _c(
                  "a-drawer",
                  {
                    attrs: {
                      wrapClassName: "drawer-sider " + _vm.navTheme,
                      placement: "left",
                      closable: false,
                      visible: _vm.collapsed,
                      width: "200px",
                    },
                    on: { close: () => (this.collapsed = false) },
                  },
                  [
                    _vm.device === "mobile"
                      ? _c("side-menu", {
                          attrs: {
                            mode: "inline",
                            menus: _vm.menus,
                            theme: _vm.navTheme,
                            collapsed: false,
                            collapsible: true,
                          },
                          on: {
                            menuSelect: _vm.menuSelect,
                            updateMenuTitle: _vm.handleUpdateMenuTitle,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c("side-menu", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.device === "desktop",
                  expression: "device === 'desktop'",
                },
              ],
              attrs: {
                mode: "inline",
                menus: _vm.menus,
                theme: _vm.navTheme,
                collapsed: _vm.collapsed,
                collapsible: true,
              },
              on: {
                menuSelect: _vm.myMenuSelect,
                updateMenuTitle: _vm.handleUpdateMenuTitle,
              },
            }),
          ]
        : [
            _vm.device === "mobile"
              ? _c(
                  "a-drawer",
                  {
                    attrs: {
                      wrapClassName: "drawer-sider " + _vm.navTheme,
                      placement: "left",
                      closable: false,
                      visible: _vm.collapsed,
                      width: "200px",
                    },
                    on: { close: () => (this.collapsed = false) },
                  },
                  [
                    _c("side-menu", {
                      attrs: {
                        mode: "inline",
                        menus: _vm.menus,
                        theme: _vm.navTheme,
                        collapsed: false,
                        collapsible: true,
                      },
                      on: {
                        menuSelect: _vm.menuSelect,
                        updateMenuTitle: _vm.handleUpdateMenuTitle,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _c(
        "a-layout",
        {
          class: [_vm.layoutMode, `content-width-${_vm.contentWidth}`],
          style: {
            paddingLeft:
              _vm.fixSiderbar && _vm.isDesktop()
                ? `${_vm.sidebarOpened ? 200 : 80}px`
                : "0",
          },
        },
        [
          _c("global-header", {
            attrs: {
              mode: _vm.layoutMode,
              menus: _vm.menus,
              theme: _vm.navTheme,
              collapsed: _vm.collapsed,
              device: _vm.device,
            },
            on: {
              toggle: _vm.toggle,
              updateMenuTitle: _vm.handleUpdateMenuTitle,
            },
          }),
          _c(
            "a-layout-content",
            {
              style: {
                height: "100%",
                paddingTop: _vm.fixedHeader ? "59px" : "0",
              },
            },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setting-drawer" },
    [
      _c(
        "a-drawer",
        {
          staticStyle: { height: "100%", overflow: "auto" },
          attrs: {
            width: "300",
            placement: "right",
            closable: false,
            visible: _vm.visible,
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "setting-drawer-index-content" },
            [
              _c("div", { style: { marginBottom: "24px" } }, [
                _c("h3", { staticClass: "setting-drawer-index-title" }, [
                  _vm._v("整体风格设置"),
                ]),
                _c(
                  "div",
                  { staticClass: "setting-drawer-index-blockChecbox" },
                  [
                    _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            "\n                暗色菜单风格\n              "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "setting-drawer-index-item",
                            on: {
                              click: function ($event) {
                                return _vm.handleMenuTheme("dark")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://gw.alipayobjects.com/zos/rmsportal/LCkqqYNmvBEbokSDscrm.svg",
                                alt: "dark",
                              },
                            }),
                            _vm.navTheme === "dark"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "setting-drawer-index-selectIcon",
                                  },
                                  [_c("a-icon", { attrs: { type: "check" } })],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            "\n                亮色菜单风格\n              "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "setting-drawer-index-item",
                            on: {
                              click: function ($event) {
                                return _vm.handleMenuTheme("light")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://gw.alipayobjects.com/zos/rmsportal/jpRkZQMyYRryryPNtyIC.svg",
                                alt: "light",
                              },
                            }),
                            _vm.navTheme !== "dark"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "setting-drawer-index-selectIcon",
                                  },
                                  [_c("a-icon", { attrs: { type: "check" } })],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { style: { marginBottom: "24px" } }, [
                _c("h3", { staticClass: "setting-drawer-index-title" }, [
                  _vm._v("主题色"),
                ]),
                _c(
                  "div",
                  { staticStyle: { height: "20px" } },
                  _vm._l(_vm.colorList, function (item, index) {
                    return _c(
                      "a-tooltip",
                      {
                        key: index,
                        staticClass: "setting-drawer-theme-color-colorBlock",
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.key) +
                              "\n              "
                          ),
                        ]),
                        _c(
                          "a-tag",
                          {
                            attrs: { color: item.color },
                            on: {
                              click: function ($event) {
                                return _vm.changeColor(item.color)
                              },
                            },
                          },
                          [
                            item.color === _vm.primaryColor
                              ? _c("a-icon", { attrs: { type: "check" } })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  }),
                  1
                ),
              ]),
              _c("a-divider"),
              _c("div", { style: { marginBottom: "24px" } }, [
                _c("h3", { staticClass: "setting-drawer-index-title" }, [
                  _vm._v("导航模式"),
                ]),
                _c(
                  "div",
                  { staticClass: "setting-drawer-index-blockChecbox" },
                  [
                    _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            "\n                侧边栏导航\n              "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "setting-drawer-index-item",
                            on: {
                              click: function ($event) {
                                return _vm.handleLayout("sidemenu")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://gw.alipayobjects.com/zos/rmsportal/JopDzEhOqwOjeNTXkoje.svg",
                                alt: "sidemenu",
                              },
                            }),
                            _vm.layoutMode === "sidemenu"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "setting-drawer-index-selectIcon",
                                  },
                                  [_c("a-icon", { attrs: { type: "check" } })],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            "\n                顶部栏导航\n              "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "setting-drawer-index-item",
                            on: {
                              click: function ($event) {
                                return _vm.handleLayout("topmenu")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://gw.alipayobjects.com/zos/rmsportal/KDNDBbriJhLwuqMoxcAr.svg",
                                alt: "topmenu",
                              },
                            }),
                            _vm.layoutMode !== "sidemenu"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "setting-drawer-index-selectIcon",
                                  },
                                  [_c("a-icon", { attrs: { type: "check" } })],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { style: { marginTop: "24px" } },
                  [
                    _c(
                      "a-list",
                      { attrs: { split: false } },
                      [
                        _c(
                          "a-list-item",
                          [
                            _c(
                              "a-tooltip",
                              { attrs: { slot: "actions" }, slot: "actions" },
                              [
                                _c("template", { slot: "title" }, [
                                  _vm._v(
                                    "\n                    该设定仅 [顶部栏导航] 时有效\n                  "
                                  ),
                                ]),
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      size: "small",
                                      defaultValue: _vm.contentWidth,
                                    },
                                    on: {
                                      change: _vm.handleContentWidthChange,
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "Fixed" } },
                                      [_vm._v("固定")]
                                    ),
                                    _vm.layoutMode !== "sidemenu"
                                      ? _c(
                                          "a-select-option",
                                          { attrs: { value: "Fluid" } },
                                          [_vm._v("流式")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _c("a-list-item-meta", [
                              _c(
                                "div",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v("内容区域宽度")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "a-list-item",
                          [
                            _c("a-switch", {
                              attrs: {
                                slot: "actions",
                                size: "small",
                                defaultChecked: _vm.fixedHeader,
                              },
                              on: { change: _vm.handleFixedHeader },
                              slot: "actions",
                            }),
                            _c("a-list-item-meta", [
                              _c(
                                "div",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v("固定 Header")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "a-list-item",
                          [
                            _c("a-switch", {
                              attrs: {
                                slot: "actions",
                                size: "small",
                                disabled: !_vm.fixedHeader,
                                defaultChecked: _vm.autoHideHeader,
                              },
                              on: { change: _vm.handleFixedHeaderHidden },
                              slot: "actions",
                            }),
                            _c("a-list-item-meta", [
                              _c(
                                "div",
                                {
                                  style: {
                                    textDecoration: !_vm.fixedHeader
                                      ? "line-through"
                                      : "unset",
                                  },
                                  attrs: { slot: "title" },
                                  slot: "title",
                                },
                                [_vm._v("下滑时隐藏 Header")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "a-list-item",
                          [
                            _c("a-switch", {
                              attrs: {
                                slot: "actions",
                                size: "small",
                                disabled: _vm.layoutMode === "topmenu",
                                checked: _vm.dataFixSiderbar,
                              },
                              on: { change: _vm.handleFixSiderbar },
                              slot: "actions",
                            }),
                            _c("a-list-item-meta", [
                              _c(
                                "div",
                                {
                                  style: {
                                    textDecoration:
                                      _vm.layoutMode === "topmenu"
                                        ? "line-through"
                                        : "unset",
                                  },
                                  attrs: { slot: "title" },
                                  slot: "title",
                                },
                                [_vm._v("固定侧边菜单")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("a-divider"),
              _c("div", { style: { marginBottom: "24px" } }, [
                _c("h3", { staticClass: "setting-drawer-index-title" }, [
                  _vm._v("其他设置"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "a-list",
                      { attrs: { split: false } },
                      [
                        _c(
                          "a-list-item",
                          [
                            _c("a-switch", {
                              attrs: {
                                slot: "actions",
                                size: "small",
                                defaultChecked: _vm.colorWeak,
                              },
                              on: { change: _vm.onColorWeak },
                              slot: "actions",
                            }),
                            _c("a-list-item-meta", [
                              _c(
                                "div",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v("色弱模式")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "a-list-item",
                          [
                            _c("a-switch", {
                              attrs: {
                                slot: "actions",
                                size: "small",
                                defaultChecked: _vm.multipage,
                              },
                              on: { change: _vm.onMultipageWeak },
                              slot: "actions",
                            }),
                            _c("a-list-item-meta", [
                              _c(
                                "div",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v("多页签模式")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("a-divider"),
              _c(
                "div",
                { style: { marginBottom: "24px" } },
                [
                  _c("a-alert", { attrs: { type: "warning" } }, [
                    _c(
                      "span",
                      { attrs: { slot: "message" }, slot: "message" },
                      [
                        _vm._v(
                          "\n              配置栏只在开发环境用于预览，生产环境不会展现，请手动修改配置文件\n              "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://github.com/sendya/ant-design-pro-vue/blob/master/src/defaultSettings.js",
                              target: "_blank",
                            },
                          },
                          [_vm._v("src/defaultSettings.js")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.visible
            ? _c(
                "div",
                {
                  staticClass: "setting-drawer-index-handle",
                  on: { click: _vm.toggle },
                },
                [_c("a-icon", { attrs: { type: "close" } })],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-layout-sider",
    {
      class: [
        "sider",
        _vm.isDesktop() ? null : "shadow",
        _vm.theme,
        _vm.fixSiderbar ? "ant-fixed-sidemenu" : null,
      ],
      attrs: { width: "220px", collapsible: _vm.collapsible, trigger: null },
      model: {
        value: _vm.collapsed,
        callback: function ($$v) {
          _vm.collapsed = $$v
        },
        expression: "collapsed",
      },
    },
    [
      _c("logo"),
      _c("s-menu", {
        style: _vm.smenuStyle,
        attrs: {
          collapsed: _vm.collapsed,
          menu: _vm.menus,
          theme: _vm.theme,
          mode: _vm.mode,
        },
        on: { select: _vm.onSelect, updateMenuTitle: _vm.onUpdateMenuTitle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-layout",
    {
      attrs: {
        desc: _vm.description,
        title: _vm.getTitle,
        "link-list": _vm.linkList,
        search: _vm.search,
        tabs: _vm.tabs,
      },
    },
    [
      _c(
        "div",
        { staticClass: "extra-img", attrs: { slot: "extra" }, slot: "extra" },
        [_c("img", { attrs: { src: _vm.extraImage } })]
      ),
      _c("route-view", { ref: "content" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
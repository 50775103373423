var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-popover",
    {
      attrs: {
        trigger: "contextmenu",
        placement: _vm.position,
        overlayClassName: "j-input-pop",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _c(
          "span",
          { staticStyle: { float: "right" }, attrs: { title: "关闭" } },
          [
            _c("a-icon", {
              attrs: { type: "close" },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-input",
        {
          attrs: {
            value: _vm.inputContent,
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
          },
          on: { change: _vm.handleInputChange },
        },
        [
          _c("a-icon", {
            attrs: { slot: "suffix", type: "fullscreen" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.pop.apply(null, arguments)
              },
            },
            slot: "suffix",
          }),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("a-textarea", {
            ref: "textarea",
            style: { height: _vm.height + "px", width: _vm.width + "px" },
            attrs: { value: _vm.inputContent, disabled: _vm.disabled },
            on: { input: _vm.handleInputChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "config-list" },
    [
      _c(
        "a-radio-group",
        {
          model: {
            value: _vm.type,
            callback: function ($$v) {
              _vm.type = $$v
            },
            expression: "type",
          },
        },
        [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "a-radio",
                {
                  staticClass: "choice",
                  attrs: { value: "TYPE_EVERY", disabled: _vm.disabled },
                },
                [_vm._v("每年")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "a-radio",
                {
                  staticClass: "choice",
                  attrs: { value: "TYPE_RANGE", disabled: _vm.disabled },
                },
                [_vm._v("区间")]
              ),
              _vm._v("\n      从\n      "),
              _c("a-input-number", {
                staticClass: "w60",
                attrs: {
                  disabled: _vm.type !== _vm.TYPE_RANGE || _vm.disabled,
                  min: 0,
                  precision: 0,
                },
                model: {
                  value: _vm.valueRange.start,
                  callback: function ($$v) {
                    _vm.$set(_vm.valueRange, "start", $$v)
                  },
                  expression: "valueRange.start",
                },
              }),
              _vm._v("\n      年\n      至\n      "),
              _c("a-input-number", {
                staticClass: "w60",
                attrs: {
                  disabled: _vm.type !== _vm.TYPE_RANGE || _vm.disabled,
                  min: 1,
                  precision: 0,
                },
                model: {
                  value: _vm.valueRange.end,
                  callback: function ($$v) {
                    _vm.$set(_vm.valueRange, "end", $$v)
                  },
                  expression: "valueRange.end",
                },
              }),
              _vm._v("\n      年\n    "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "a-radio",
                {
                  staticClass: "choice",
                  attrs: { value: "TYPE_LOOP", disabled: _vm.disabled },
                },
                [_vm._v("循环")]
              ),
              _vm._v("\n      从\n      "),
              _c("a-input-number", {
                staticClass: "w60",
                attrs: {
                  disabled: _vm.type !== _vm.TYPE_LOOP || _vm.disabled,
                  min: 0,
                  precision: 0,
                },
                model: {
                  value: _vm.valueLoop.start,
                  callback: function ($$v) {
                    _vm.$set(_vm.valueLoop, "start", $$v)
                  },
                  expression: "valueLoop.start",
                },
              }),
              _vm._v("\n      年开始，间隔\n      "),
              _c("a-input-number", {
                staticClass: "w60",
                attrs: {
                  disabled: _vm.type !== _vm.TYPE_LOOP || _vm.disabled,
                  min: 1,
                  precision: 0,
                },
                model: {
                  value: _vm.valueLoop.interval,
                  callback: function ($$v) {
                    _vm.$set(_vm.valueLoop, "interval", $$v)
                  },
                  expression: "valueLoop.interval",
                },
              }),
              _vm._v("\n      年\n    "),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.boxClass }, [
    _c(
      "div",
      { staticClass: "j-vxe-tool-button div", attrs: { size: _vm.btnSize } },
      [
        _vm.showPrefix
          ? _vm._t("toolbarPrefix", null, { size: _vm.btnSize })
          : _vm._e(),
        _vm.showAdd
          ? _c(
              "a-button",
              {
                attrs: {
                  icon: "plus",
                  disabled: _vm.disabled,
                  type: "primary",
                },
                on: {
                  click: function ($event) {
                    return _vm.trigger("add")
                  },
                },
              },
              [_vm._v("新增")]
            )
          : _vm._e(),
        _vm.showSave
          ? _c(
              "a-button",
              {
                attrs: { icon: "save", disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    return _vm.trigger("save")
                  },
                },
              },
              [_vm._v("保存")]
            )
          : _vm._e(),
        _vm.selectedRowIds.length > 0
          ? [
              _vm.showRemove
                ? _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: `确定要删除这 ${_vm.selectedRowIds.length} 项吗?`,
                      },
                      on: {
                        confirm: function ($event) {
                          return _vm.trigger("remove")
                        },
                      },
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { icon: "minus", disabled: _vm.disabled } },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showClearSelection
                ? [
                    _c(
                      "a-button",
                      {
                        attrs: { icon: "delete" },
                        on: {
                          click: function ($event) {
                            return _vm.trigger("clearSelection")
                          },
                        },
                      },
                      [_vm._v("清空选择")]
                    ),
                  ]
                : _vm._e(),
            ]
          : _vm._e(),
        _vm.showSuffix
          ? _vm._t("toolbarSuffix", null, { size: _vm.btnSize })
          : _vm._e(),
        _vm.showCollapse
          ? _c(
              "a",
              {
                staticStyle: { "margin-left": "4px" },
                on: { click: _vm.toggleCollapse },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.collapsed ? "展开" : "收起"))]),
                _c("a-icon", {
                  attrs: { type: _vm.collapsed ? "down" : "up" },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
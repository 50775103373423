var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "global-layout",
    [
      _c(
        "transition",
        { attrs: { name: "page-transition" } },
        [
          _vm.keepAlive
            ? _c("keep-alive", [_c("router-view")], 1)
            : _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.query
        ? _c(
            "a-select",
            {
              staticStyle: { width: "100%" },
              on: { change: _vm.handleSelectChange },
            },
            _vm._l(_vm.queryOption, function (item, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: item.value } },
                [_vm._v("\n      " + _vm._s(item.text) + "\n    ")]
              )
            }),
            1
          )
        : _c("a-switch", {
            attrs: { disabled: _vm.disabled },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.checkStatus,
              callback: function ($$v) {
                _vm.checkStatus = $$v
              },
              expression: "checkStatus",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
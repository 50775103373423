var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-table",
    _vm._g(
      _vm._b(
        {
          attrs: {
            rowKey: _vm.rowKey,
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            expandedRowKeys: _vm.expandedRowKeys,
          },
          on: {
            expand: _vm.handleExpand,
            expandedRowsChange: function ($event) {
              _vm.expandedRowKeys = $event
            },
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.slots, function (slotItem) {
                return {
                  key: slotItem,
                  fn: function (text, record, index) {
                    return [
                      _vm._t(slotItem, null, null, { text, record, index }),
                    ]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "a-table",
        _vm.tableAttrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
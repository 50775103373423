var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-progress",
    _vm._b(
      { class: _vm.clazz, attrs: { percent: _vm.innerValue, size: "small" } },
      "a-progress",
      _vm.cellProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
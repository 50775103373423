var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-time-picker",
    _vm._b(
      {
        ref: "timePicker",
        staticStyle: { "min-width": "0" },
        attrs: {
          value: _vm.innerDateValue,
          allowClear: "",
          dropdownClassName: "j-vxe-date-picker",
        },
        on: { change: _vm.handleChange },
      },
      "a-time-picker",
      _vm.cellProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
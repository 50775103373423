var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.async
    ? _c(
        "a-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            showSearch: "",
            labelInValue: "",
            disabled: _vm.disabled,
            getPopupContainer: _vm.getParentContainer,
            placeholder: _vm.placeholder,
            filterOption: false,
            allowClear: "",
            notFoundContent: _vm.loading ? undefined : null,
          },
          on: { search: _vm.loadData, change: _vm.handleAsyncChange },
          model: {
            value: _vm.selectedAsyncValue,
            callback: function ($$v) {
              _vm.selectedAsyncValue = $$v
            },
            expression: "selectedAsyncValue",
          },
        },
        [
          _vm.loading
            ? _c("a-spin", {
                attrs: { slot: "notFoundContent", size: "small" },
                slot: "notFoundContent",
              })
            : _vm._e(),
          _vm._l(_vm.options, function (d) {
            return _c(
              "a-select-option",
              { key: d.value, attrs: { value: d.value } },
              [_vm._v(_vm._s(d.text))]
            )
          }),
        ],
        2
      )
    : _c(
        "a-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            getPopupContainer: _vm.getParentContainer,
            showSearch: "",
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            optionFilterProp: "children",
            filterOption: _vm.filterOption,
            allowClear: "",
            notFoundContent: _vm.loading ? undefined : null,
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.selectedValue,
            callback: function ($$v) {
              _vm.selectedValue = $$v
            },
            expression: "selectedValue",
          },
        },
        [
          _vm.loading
            ? _c("a-spin", {
                attrs: { slot: "notFoundContent", size: "small" },
                slot: "notFoundContent",
              })
            : _vm._e(),
          _vm._l(_vm.options, function (d) {
            return _c(
              "a-select-option",
              { key: d.value, attrs: { value: d.value } },
              [_vm._v(_vm._s(d.text))]
            )
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
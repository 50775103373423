var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "links" }, [
      _c("a", { attrs: { href: "http://www.jeecg.com", target: "_blank" } }, [
        _vm._v("JEECG 首页"),
      ]),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/zhangdaiscott/jeecg-boot",
            target: "_blank",
          },
        },
        [_c("a-icon", { attrs: { type: "github" } })],
        1
      ),
      _c("a", { attrs: { href: "https://ant.design/" } }, [
        _vm._v("Ant Design"),
      ]),
      _c(
        "a",
        {
          attrs: {
            href: "https://vuecomponent.github.io/ant-design-vue/docs/vue/introduce-cn/",
          },
        },
        [_vm._v("Vue Antd")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "copyright" },
      [
        _vm._v("\n    Copyright\n    "),
        _c("a-icon", { attrs: { type: "copyright" } }),
        _vm._v("\n    2019 "),
        _c("span", [_vm._v("JEECG开源社区 出品")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }